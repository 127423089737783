/* eslint-disable max-len */
/* eslint no-bitwise: 0 */

const TVKEYS = {};

window.KeyEvent = window.KeyEvent || {};

/**
 * Key code for the red color button
 * @instance
 * @constant
 */
TVKEYS.RED = 0x1;

/**
 * Key code for the green color button
 * @instance
 * @constant
 */
TVKEYS.GREEN = 0x2;

/**
 * Key code for the yellow color button
 * @instance
 * @constant
 */
TVKEYS.YELLOW = 0x4;

/**
 * Key code for the blue color button
 * @instance
 * @constant
 */
TVKEYS.BLUE = 0x8;

/**
 * Key code for all color buttons
 * @instance
 * @constant
 */
TVKEYS.COLOR = 0xF;

/**
 * Key code for the navigation key set (arrow keys and OK button)
 * @instance
 * @constant
 */
TVKEYS.NAVIGATION = 0x10;

/**
 * Key code for the play/pause, play, pause and stop buttons
 * @instance
 * @constant
 */
TVKEYS.VCR = 0x20;

/**
 * Key code the numeric buttons (0, 1, 2, ... 9)
 * @instance
 * @constant
 */
TVKEYS.NUMERIC = 0x100;

/**
 * Key code for no button
 * @instance
 * @constant
 */
TVKEYS.NONE = 0x0;

/**
 * Key code for all buttons
 * @instance
 * @constant
 */
TVKEYS.ALL = 0x13F;
TVKEYS.FULL = 0x33F;

TVKEYS.WINDOW_RED = 403;
TVKEYS.WINDOW_BACK = 461;
TVKEYS.WINDOW_GREEN = 404;
TVKEYS.WINDOW_YELLOW = 405;
TVKEYS.WINDOW_BLUE = 406;
TVKEYS.WINDOW_ENTER = 13;

TVKEYS.DEV = TVKEYS.VK_ENTER | TVKEYS.RED | TVKEYS.GREEN | TVKEYS.YELLOW | TVKEYS.BLUE | TVKEYS.NAVIGATION | TVKEYS.NUMERIC;
TVKEYS.HIDDEN = TVKEYS.RED | TVKEYS.GREEN | TVKEYS.YELLOW | TVKEYS.BLUE;

TVKEYS.VK_LEFT = (typeof window.KeyEvent.VK_LEFT !== 'undefined' ? window.KeyEvent.VK_LEFT : 0x25);
TVKEYS.VK_UP = (typeof window.KeyEvent.VK_UP !== 'undefined' ? window.KeyEvent.VK_UP : 0x26);
TVKEYS.VK_RIGHT = (typeof window.KeyEvent.VK_RIGHT !== 'undefined' ? window.KeyEvent.VK_RIGHT : 0x27);
TVKEYS.VK_DOWN = (typeof window.KeyEvent.VK_DOWN !== 'undefined' ? window.KeyEvent.VK_DOWN : 0x28);
TVKEYS.VK_ENTER = (typeof window.KeyEvent.VK_ENTER !== 'undefined' ? window.KeyEvent.VK_ENTER : 0x0d);
TVKEYS.VK_BACK = (typeof window.KeyEvent.VK_BACK !== 'undefined' ? window.KeyEvent.VK_BACK : 0xa6);

TVKEYS.VK_RED = (typeof window.KeyEvent.VK_RED !== 'undefined' ? window.KeyEvent.VK_RED : 0x74);
TVKEYS.VK_GREEN = (typeof window.KeyEvent.VK_GREEN !== 'undefined' ? window.KeyEvent.VK_GREEN : 0x75);
TVKEYS.VK_YELLOW = (typeof window.KeyEvent.VK_YELLOW !== 'undefined' ? window.KeyEvent.VK_YELLOW : 0x76);
TVKEYS.VK_BLUE = (typeof window.KeyEvent.VK_BLUE !== 'undefined' ? window.KeyEvent.VK_BLUE : 0x77);

TVKEYS.VK_PLAY = (typeof window.KeyEvent.VK_PLAY !== 'undefined' ? window.KeyEvent.VK_PLAY : 0x50);
TVKEYS.VK_PAUSE = (typeof window.KeyEvent.VK_PAUSE !== 'undefined' ? window.KeyEvent.VK_PAUSE : 0x51);
TVKEYS.VK_STOP = (typeof window.KeyEvent.VK_STOP !== 'undefined' ? window.KeyEvent.VK_STOP : 0x53);
TVKEYS.VK_PLAY_PAUSE = (typeof window.KeyEvent.VK_PLAY_PAUSE !== 'undefined' ? window.KeyEvent.VK_PLAY_PAUSE : 0);

TVKEYS.VK_FAST_FWD = (typeof window.KeyEvent.VK_FAST_FWD !== 'undefined' ? window.KeyEvent.VK_FAST_FWD : 0x46);
TVKEYS.VK_REWIND = (typeof window.KeyEvent.VK_REWIND !== 'undefined' ? window.KeyEvent.VK_REWIND : 0x52);

TVKEYS.VK_PAGE_UP = (typeof window.KeyEvent.VK_PAGE_UP !== 'undefined' ? window.KeyEvent.VK_PAGE_UP : 0x21);
TVKEYS.VK_PAGE_DOWN = (typeof window.KeyEvent.VK_PAGE_DOWN !== 'undefined' ? window.KeyEvent.VK_PAGE_DOWN : 0x22);

TVKEYS.VK_0 = (typeof window.KeyEvent.VK_0 !== 'undefined' ? window.KeyEvent.VK_0 : 0x30);
TVKEYS.VK_1 = (typeof window.KeyEvent.VK_1 !== 'undefined' ? window.KeyEvent.VK_1 : 0x31);
TVKEYS.VK_2 = (typeof window.KeyEvent.VK_2 !== 'undefined' ? window.KeyEvent.VK_2 : 0x32);
TVKEYS.VK_3 = (typeof window.KeyEvent.VK_3 !== 'undefined' ? window.KeyEvent.VK_3 : 0x33);
TVKEYS.VK_4 = (typeof window.KeyEvent.VK_4 !== 'undefined' ? window.KeyEvent.VK_4 : 0x34);
TVKEYS.VK_5 = (typeof window.KeyEvent.VK_5 !== 'undefined' ? window.KeyEvent.VK_5 : 0x35);
TVKEYS.VK_6 = (typeof window.KeyEvent.VK_6 !== 'undefined' ? window.KeyEvent.VK_6 : 0x36);
TVKEYS.VK_7 = (typeof window.KeyEvent.VK_7 !== 'undefined' ? window.KeyEvent.VK_7 : 0x37);
TVKEYS.VK_8 = (typeof window.KeyEvent.VK_8 !== 'undefined' ? window.KeyEvent.VK_8 : 0x38);
TVKEYS.VK_9 = (typeof window.KeyEvent.VK_9 !== 'undefined' ? window.KeyEvent.VK_9 : 0x39);

if (process.env.NODE_ENV === 'development') {
  TVKEYS.VK_RED = (typeof window.KeyEvent.VK_RED !== 'undefined' ? window.KeyEvent.VK_RED : TVKEYS.WINDOW_RED);
  TVKEYS.VK_GREEN = (typeof window.KeyEvent.VK_GREEN !== 'undefined' ? window.KeyEvent.VK_GREEN : TVKEYS.WINDOW_GREEN);
  TVKEYS.VK_YELLOW = (typeof window.KeyEvent.VK_YELLOW !== 'undefined' ? window.KeyEvent.VK_YELLOW : TVKEYS.WINDOW_YELLOW);
  TVKEYS.VK_BLUE = (typeof window.KeyEvent.VK_BLUE !== 'undefined' ? window.KeyEvent.VK_BLUE : TVKEYS.WINDOW_BLUE);
  TVKEYS.VK_ENTER = (typeof window.KeyEvent.VK_ENTER !== 'undefined' ? window.KeyEvent.VK_ENTER : TVKEYS.WINDOW_ENTER);
  TVKEYS.VK_BACK = (typeof window.KeyEvent.VK_BACK !== 'undefined' ? window.KeyEvent.VK_BACK : TVKEYS.WINDOW_BACK);
}

window.TVKEYS = TVKEYS;
window.KeyEvent = Object.keys(window.KeyEvent).length ? window.KeyEvent : {...TVKEYS};

const ifDigits = (keyCode) => {
  const digits = [
    TVKEYS.VK_1,
    TVKEYS.VK_2,
    TVKEYS.VK_3,
    TVKEYS.VK_4,
    TVKEYS.VK_5,
    TVKEYS.VK_6,
    TVKEYS.VK_7,
    TVKEYS.VK_8,
    TVKEYS.VK_9,
    TVKEYS.VK_0,
  ];
  return digits.includes(keyCode);
};
const getNumberOfKey = (keyCode) => Number(keyCode) - 48;

TVKEYS.helpers = {
  ifDigits,
  getNumberOfKey,
};

export default TVKEYS;
