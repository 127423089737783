import AbstractView from './AbstractView.js';
import TVKEYS from '../tv/tvkeys.js';
import combineSlideHelpers from '../helpers/slideHelper.js';
import { broadcastStartAfterStop, broadcastStop, clearMediaVideo, deactivateApp } from '../components/broadcast-object.js';
import moment from 'moment';
import axios from 'axios';
class MediaPlayer extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Dizi ve Programlar');
    this.setActiveHeader("header-tvshows");
    this.queryString = window.location.search;
    this.urlParams = new URLSearchParams(this.queryString);
    this.mediaIndex = this.urlParams.get('index');
    this.programName = this.urlParams.get('name');
    this.mediaLink = this.urlParams.get('media');
    this.programId = this.urlParams.get('detail-id');
    this.detailId = params.id;
    this.episodesdata = null;

    this.navigationHandler = null;

    this.mediaResizeStatus = false;
    this.allowResize = false;
    this.mediaTimer = null;

    this.hoverPlayer = false;
    this.mediaValid = true;
    this.containers = '.media-back-wrapper, .resize-media-wrapper, .controls';
  }

  addHoverPlayer() {
    const mediaPlayer = document.querySelector('.media-player');
    mediaPlayer.classList.add('hover-player');
  }
  removeHoverPlayer() {
    const mediaPlayer = document.querySelector('.media-player');
    mediaPlayer.classList.remove('hover-player');
  }

  forwardVideo() {
    const video = document.getElementById('mediaVideo');
    video.currentTime += 5;
  }
  rewindVideo() {
    const video = document.getElementById('mediaVideo');
    video.currentTime -= 5;
  }

  startTimer() {
    let counter = 10;
    const resizeBtn = document.querySelector(".resize-media");
    resizeBtn.innerHTML = `<i class="fa-solid fa-expand"></i> ${counter}sn`;
    this.mediaTimer = setInterval(() => {
      counter--;
      if (counter === 0) {
        this.allowResize = true;
        clearInterval(this.mediaTimer);
        resizeBtn.innerHTML = '<i class="fa-solid fa-expand"></i>';
      } else {
        resizeBtn.innerHTML = `<i class="fa-solid fa-expand"></i> ${counter}sn`;
      }
    }, 1000);
  }

  resizeVideo() {
    if (!this.allowResize) return;
    const player = document.querySelector(".media-player");
    if (!this.mediaResizeStatus) {
      player.style.width = "1280px";
      player.style.height = "720px";
      player.style.marginTop = 0;
      document.querySelector(".resize-media").innerHTML = '<i class="fa-solid fa-compress"></i>';
      this.mediaResizeStatus = true;
    } else {
      player.style.width = "864px";
      player.style.height = "486px";
      player.style.marginTop = "234px";
      document.querySelector(".resize-media").innerHTML = '<i class="fa-solid fa-expand"></i>';
      this.mediaResizeStatus = false;
    }
  }

  async getNextVideos() {
    const response = await axios.get(`https://hbbappapi.tvekstra.com/api/content_detail/season/${this.programId}/MT01/`);
    this.episodesdata = response.data;
  }

  renderNextVideos() {
    return this.episodesdata.map((item, index) => {
      const mp4File = item.media_files.find(file => file.file_type === "video/mp4");

      const link = mp4File ? mp4File.file : "defaultURL";

      return (
        `
        <div class="next-video" href="/media/${this.detailId}?name=${item.title}&amp;media=${link}&amp;index=${index}" data-link="" tabindex="-1">
          <img class="next-video-img" src="${item.content_season.media_files[0].file}" alt="next-video" />
          <div class="next-video-detail">
            <div class="description">${item.title}</div>
          </div>
        </div>
        `
      );
    }).join('');
  }



  async getHtml() {
    broadcastStop();
    // await this.getNextVideos();
    if (this.mediaLink == "errorMedia") {
      this.containers = '.media-back-wrapper';
      return `
  
    <div class="custom-player">
      <div class="media-player">

        <div class="media-back-wrapper">
          <div class="media-go-back" tabindex="-1">
          <span class="media-back-link" href="${localStorage.getItem('pagePath')}" data-link=""></span>
          <i class="fas fa-arrow-left"></i> Geri
          </div>
        </div>

        <div class="media-error-message">Medya Dosyası Bulunamadı</div>

        <div class="media-name">${this.programName}</div>
        <div class="next-videos-wrapper">
          <div class="mb-16"></div>
          <div class="next-video-list">

            
          
          </div>
        </div>
      </div>
    </div>
    `;
    }
    else {
      return `
  
    <div class="custom-player">
      <div class="media-player">

        <div class="media-back-wrapper">
          <div class="media-go-back" tabindex="-1">
          <span class="media-back-link" href="${localStorage.getItem('pagePath')}" data-link="" ></span>
          <i class="fas fa-arrow-left"></i> Geri
          </div>
        </div>

        <div class="resize-media-wrapper"><span class="resize-media" tabindex="-1">Ekranı Büyüt</span></div>

        <video id="mediaVideo" width="1280" height="720" autoplay="autoplay">
          <source src="${this.mediaLink}" type="video/mp4" />
        </video>
        <div class="media-name">${this.programName}</div>
        <div class="controls">
          <div class="play-pause" tabindex="-1">
            <i class="fas fa-pause"></i>
          </div>
          <div class="media-progress-wrapper">
            <div class="media-time"></div>
            <div class="progress-bar">
              <div class="progress-circle"></div>
            </div>
            <div class="remaining-time"></div>
          </div>
        </div>
        <div class="next-videos-wrapper">
          <div class="mb-16"></div>
          <div class="next-video-list">

            
          
          </div>
        </div>
      </div>
    </div>
    `;
    }
  }

  async navigate() {
    const that = this;
    const containers = document.querySelectorAll(this.containers);
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex;
    this.mediaLink == "errorMedia" ? currentBlockIndex = 0 : currentBlockIndex = 2;

    document.addEventListener('keydown', this.navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                clearInterval(that.mediaTimer);
                document.removeEventListener('keydown', that.navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentContainerIndex == 2 && currentBlockIndex == 1) {
              that.rewindVideo();
            } else if (currentBlockIndex > 0) {
              currentBlockIndex--;
            }
            if (currentContainerIndex == 3) {
              combineSlideHelpers.nextVideosX(currentBlockIndex, containers[2]);
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentContainerIndex == 2 && currentBlockIndex == 1) {
              that.forwardVideo();
            } else if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
            }
            if (currentContainerIndex == 3) {
              combineSlideHelpers.nextVideosX(currentBlockIndex, containers[2]);
            }
            break;
          case TVKEYS.VK_UP:
            if (!that.hoverPlayer) {
              that.addHoverPlayer();
              that.hoverPlayer = true;
              currentBlockIndex = 0;
              currentContainerIndex = 3;
            }
            if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
            }
            break;
          case TVKEYS.VK_DOWN:
            if (that.hoverPlayer) {
              if (currentContainerIndex == 3) {
                that.removeHoverPlayer();
                that.hoverPlayer = false;
              }
              if (currentContainerIndex < containers.length - 1) {
                currentContainerIndex++;
                currentBlockIndex = 0;
              }
            } else {
              that.addHoverPlayer();
              that.hoverPlayer = true;
              currentBlockIndex = 0;
              currentContainerIndex = 2;
            }
            break;
          case TVKEYS.VK_BACK:
            clearInterval(that.mediaTimer);
            clearMediaVideo();
            document.removeEventListener('keydown', that.navigationHandler);
            document.querySelector('.media-back-link').click();
            setTimeout(() => {
              broadcastStartAfterStop();
            }, 500);
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }


  setPlayerAdvertisements = () => {
    async function getAdvertisements(url) {
      try {
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    }

    const url = 'https://hbbappapi.tvekstra.com/api/advertisements/current/player/';
    getAdvertisements(url)
      .then(data => {
        const defaultUrl = "https://dummyimage.com/1280x720/000000/ffffff.png";
        const mediaSrc = data && data[0] && data[0].media_files && data[0].media_files[0] && data[0].media_files[0].file ? data[0].media_files[0].file : defaultUrl;
        const customPlayer = document.querySelector(".custom-player");
        customPlayer.style.backgroundImage = `url("${mediaSrc}")`;

        if (!data || !data.length) {
          this.resizeVideo();
          clearInterval(this.mediaTimer);
          this.allowResize = true;
          this.resizeVideo();

        }
      })
      .catch(error => {
        console.log('An error occurred:', error.message);
      });
  };


  async afterRender() {

    this.startTimer();

    this.setPlayerAdvertisements();
    const mediaBack = document.querySelector(".media-go-back");
    mediaBack.addEventListener('click', () => {
      clearInterval(this.mediaTimer);
      clearMediaVideo();
      document.removeEventListener('keydown', this.navigationHandler);
      document.querySelector('.media-back-link').click();
      setTimeout(() => {
        broadcastStartAfterStop();
      }, 500);
    });
    if (this.mediaLink != "errorMedia") {
      const videoPlayer = document.querySelector('.media-player');
      const video = videoPlayer.querySelector('video');
      const resizeElem = videoPlayer.querySelector(".resize-media");
      const playPauseBtn = videoPlayer.querySelector('.play-pause');
      const progressBar = videoPlayer.querySelector('.progress-bar');
      const time = videoPlayer.querySelector('.media-time');
      const remainingTimeElem = videoPlayer.querySelector('.remaining-time');

      const togglePlayPause = function () {
        if (video.paused) {
          video.play();
          playPauseBtn.innerHTML = '<i class="fas fa-pause"></i>';
        } else {
          video.pause();
          playPauseBtn.innerHTML = '<i class="fas fa-play"></i>';
        }
      };


      const updateProgressBar = function () {
        const percent = (video.currentTime / video.duration) * 100;
        progressBar.style.width = `${percent}%`;
        const timeText = moment().startOf('day').seconds(video.currentTime).format('mm:ss');
        time.textContent = timeText;
        const remainingTime = moment.utc(video.duration * 1000).format('mm:ss');
        remainingTimeElem.textContent = remainingTime;
      };

      playPauseBtn.addEventListener('click', togglePlayPause);
      video.addEventListener('timeupdate', updateProgressBar);
      resizeElem.addEventListener('click', this.resizeVideo.bind(this));

    } else {
      this.addHoverPlayer();
    }
  }


}

export default MediaPlayer;
