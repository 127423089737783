
const broadcastObject = {
  videoObj: null,
  currentLiveChannel: null,
  initialize: function() {
    broadcastObject.videoObj = document.getElementById('broadcast-video');
    broadcastObject.videoObj.addEventListener('PlayStateChange', broadcastObject.playStateChangeEventHandler);
    try {
      broadcastObject.videoObj.bindToCurrentChannel();
      broadcastObject.videoObj.setFullScreen(false);
      broadcastObject.currentLiveChannel = broadcastObject.videoObj.currentChannel;
      return true;
    } catch (error) {
      broadcastObject.currentLiveChannel = null;
      return false;
    }
  },
  stop: function() {
    try {
      if (this.videoObj != null) {
        this.videoObj.stop();
      }
    } catch (error) {
      // const consoleArea = document.getElementById('console-area');
      // const listItem = document.createElement('div');
      // listItem.textContent = error.toString();
      // consoleArea.appendChild(listItem);
      console.log("error->", error);
    }
  },
  getChannelList: function() {
    try {
      return broadcastObject.videoObj.getChannelConfig().channelList;
    } catch (error) {
      return null;
    }
  },
  getChannelInfo: function(ch) {
    const channelInfo = '' + ch.name + '(' + ch.onid + ',' + ch.tsid + ',' + ch.sid + ')';
    return channelInfo;
  },
  playStateChangeEventHandler: function() {
    const playStateField = document.getElementById('playState_field');
    if (playStateField) {
      switch (broadcastObject.videoObj.playState) {
        case 0: // unrealized
          playStateField.innerHTML = 'Unrealized';
          break;
        case 1: // connecting
          playStateField.innerHTML = 'Connecting';
          break;
        case 2: // presenting
          playStateField.innerHTML = 'Presenting';
          break;
        case 3: // stopped
          playStateField.innerHTML = 'Stopped';
          break;
        default:
          playStateField.innerHTML = 'Error';
      }
    }
  },
};

// app entry function
export function broadcastStart() {
  try {
    // attempt to acquire the Application object
    const appManager = document.getElementById('application-manager');
    const appObject = appManager.getOwnerApplication(document);
    // check if Application object was a success
    if (appObject === null) {
      // error acquiring the Application object!
    } else {
      // we have the Application object, and we proceed with broadcast_object initialization
      if (broadcastObject.initialize()) {
        // initialization OK, so display message and current channel
        document.getElementById('inititalization_field').innerHTML = 'Success';
        if (broadcastObject.currentLiveChannel !== null) {
          document.getElementById('currentChannel_field').innerHTML = broadcastObject.getChannelInfo(broadcastObject.currentLiveChannel);
        } else {
          document.getElementById('currentChannel_field').innerHTML = 'null';
        }
      } else {
        // initialization not OK, so show the message
        document.getElementById('inititalization_field').innerHTML = 'Failure';
      }
      // show our app
      appObject.show();
    }
  } catch (e) {
    // this is not an HbbTV client, catch the error.
  }
}

export function deactivateApp() {
  const appManager = document.getElementById('application-manager');
  const appObject = appManager.getOwnerApplication(document);
  appObject.destroyApplication();
}


export function broadcastStop() {
  broadcastObject.stop();
}

export function broadcastStartAfterStop() {
  clearMediaVideo();
  broadcastObject.initialize();
}

export function clearMediaVideo() {
  const mediaVideo = document.getElementById('mediaVideo');
  if (mediaVideo) {
    mediaVideo.style.display = 'none';
    mediaVideo.src = '';
  }
}
