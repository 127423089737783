import Home from '../views/Home';
import Streaming from '../views/Streaming';
import News from '../views/News';
import TvShows from '../views/TvShows';
import ProgramDetail from '../views/ProgramDetail';
import Games from '../views/Games';
import ProgramClips from '../views/ProgramClips';
import Search from '../views/Search';
import MediaPlayer from '../views/MediaPlayer';
import NewsDetail from '../views/NewsDetail';
import Pacman from '../views/Games/Pacman';
import StreamingDetail from '../views/StreamingDetail';
import MazeGame from '../views/Games/MazeGame';

class Router {
  static pathToRegex(path) {
    const regex = '^' + path.replace(/\//g, '\\/').replace(/:\w+/g, '(.+)') + '$';
    return new RegExp(regex);
  }

  static getParams(match) {
    console.log('Start get params');
    const values = match.result.slice(1);
    const regex = /:(\w+)/g;
    const keys = [];
    let matchResult;
    while ((matchResult = regex.exec(match.route.path)) !== null) {
      keys.push(matchResult[1]);
    }
    const entries = {};
    for (let i = 0; i < keys.length; i++) {
      entries[keys[i]] = values[i];
    }
    console.log('End get params');
    return entries;
  }

  constructor() {
    this.app = document.querySelector('#hbbApp');
    this.routes = [
      {path: '/', View: Home},
      {path: '/home', View: Home},
      {path: '/streaming', View: Streaming},
      {path: '/streaming-detail/:id', View: StreamingDetail},
      {path: '/news', View: News},
      {path: '/news', View: News},
      {path: '/news/detail/:id', View: NewsDetail},
      {path: '/tv-shows', View: TvShows},
      {path: '/program-detail/:id', View: ProgramDetail},
      {path: '/clips/:id', View: ProgramClips},
      {path: '/games', View: Games},
      {path: '/games/pacman', View: Pacman},
      {path: '/games/maze-game', View: MazeGame},
      {path: '/search', View: Search},
      {path: '/media/:id', View: MediaPlayer},
    ];
    this.currentRoute = null;
    this.targetUrl = '/';
  }


  getDefaultRoute() {
    this.defaultRoute = {
      route: this.routes[0],
      result: [this.targetUrl],
    };
    return this.defaultRoute;
  }

  findMatchingRoutes() {
    console.log('find match routes started');
    const potentialMatches = this.routes.map((route) => {
      return {
        route: route,
        result: this.targetUrl.match(Router.pathToRegex(route.path)),
      };
    },
    );
    let match;
    for (let i = 0; i < potentialMatches.length; i++) {
      if (potentialMatches[i].result !== null) {
        match = potentialMatches[i];
        break;
      }
    }
    if (!match) {
      match = this.getDefaultRoute();
    }
    this.result = match;
  }

  async renderRoute() {
    console.log('start get params');
    const params = Router.getParams(this.result);
    console.log('end get params');
    console.log('start view');
    const view = new this.result.route.View(params);
    console.log('end view');
    console.log(JSON.stringify(view));
    // this.app.innerHTML = await view.getLoadingHtml();
    this.app.innerHTML = await view.getHtml();
    // this.clearOldActiveStatuses();
    console.log('Navigate');
    view.navigate();
    console.log('view afterRender');
    await view.afterRender();
    console.log('end view afterRender');
  }

  navigateToRoute(url) {
    console.log('navigate to url:');
    this.targetUrl = url;
    this.findMatchingRoutes();
    history.pushState(null, null, url);
    this.renderRoute().then((function(e) {
      console.log(e);
    })).catch((e)=> console.log(e));
    console.log('end afterRenderRoute');
  }
}


export default Router;
