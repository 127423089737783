import '../styles/normalize.scss';
import '../styles/index.scss';
import '../styles/games.scss';
import '../styles/maze-game.scss';
import './components/forecast';
// import './components/currency';


import Router from './routes/route';
import { broadcastStart } from './components/broadcast-object';
import combineHelpers from './helpers/helper';
import keyConfig from './tv/keyConfig';

console.log('app started indexjs');
class App {
  constructor() {
    this.router = new Router();
    this.addEventListeners();
  }

  addEventListeners() {
    const that = this;

    document.addEventListener('DOMContentLoaded', () => {
      that.router.navigateToRoute('/');
      document.body.addEventListener('click', (e) => {
        const path = window.location.pathname;
        localStorage.setItem('pagePath', path);
        if (e.target.matches('[data-link]')) {
          e.preventDefault();
          that.router.navigateToRoute(e.target.getAttribute('href'));
        }
      });
      broadcastStart();
      keyConfig.setKeyset("full");
    });
  }
}


const appStart = () => {
  const app = new App();
  console.log(app);
};

combineHelpers.initializeClock();
combineHelpers.setAdvertisements();


appStart();
