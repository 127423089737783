import combineHelpers from "../helpers/helper";

class AbstractView {
  constructor(params) {
    this.params = params;
  }

  setTitle(title) {
    document.title = title;
  }
  
  setActiveHeader(header){
    combineHelpers.setActiveHeader(header);
  }

  async getLoadingHtml() {
    return '';
  }

  async getHtml() {
    return '';
  }

  navigate() {
  }

  async afterRender() {
    return;
  }
}

export default AbstractView;
