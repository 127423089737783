import AbstractView from './AbstractView.js';
import TVKEYS from '../tv/tvkeys.js';
import { deactivateApp } from '../components/broadcast-object.js';
import pacman from '../../images/games/pacman/pacman.png';
import maze from '../../images/games/maze-game/cover.png';
class Games extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Ana Sayfa');
    this.setActiveHeader('header-games');
  }

  async getHtml() {
    return `
    <div class="left-content">
      <div class="game-list">
      
        <div class="game-item"  href="/games/pacman" data-link="" tabindex="-1" >
          <img src="${pacman}" alt="program" />
          <div class="game-title">
            Pacman
          </div>
        </div>

        <div class="game-item"  href="/games/maze-game" data-link="" tabindex="-1" >
          <img src="${maze}" alt="program" />
          <div class="game-title">
            Labirent
          </div>
        </div>

      </div>
    </div>
  
    `;
  }

  async navigate() {
    const containers = document.querySelectorAll('.header-wrapper, .game-list');
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex = 4;

    let navigationHandler = null;
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentBlockIndex > 0) {
              currentBlockIndex--;
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
            }
            break;
          case TVKEYS.VK_UP:
            if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
            }
            break;
          case TVKEYS.VK_DOWN:
            if (currentContainerIndex < containers.length - 1) {
              currentContainerIndex++;
              currentBlockIndex = 0;
            }
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }



}

export default Games;
