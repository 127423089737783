const changeTvShowsX = (blockIndex, container) => {
  let calculateSize = -(203 * blockIndex) + 406;
  if (blockIndex < 3) {
    calculateSize = 0;
  }
  if (container) {
    container.style.transform = `translateX(${calculateSize}px)`;
  }
};
const slideStreamingY = (blockIndex, containerIndex) => {
  const container = document.querySelector(".streaming-list");
  const perBlockHeight = 144;
  const blocksPerSlide = 3;
  const initialOffset = 144;

  if (containerIndex === 2) {
    let calculateSize = blockIndex < blocksPerSlide
      ? 0
      : -(perBlockHeight * Math.floor(blockIndex / blocksPerSlide)) + initialOffset;

    container.style.transform = `translateY(${calculateSize}px)`;
  }
};

const slideHomeX = (blockIndex, containerIndex) => {
  const container = document.querySelector(".program-series");
  let calculateSize = -(269 * blockIndex) + 269;
  if (blockIndex < 2) {
    calculateSize = 0;
  }
  if (containerIndex === 2) {
    container.style.transform = `translateX(${calculateSize}px)`;
  }
};

const slideTvShowsY = (blockIndex, containerIndex) => {
  const container = document.querySelector(".tvshows-container");
  const perBlockHeight = 254;
  const initialOffset = 0;
  const fixedContainer = 2;
  if (containerIndex > 0) {
    let calculateSize = containerIndex <= fixedContainer
      ? 0
      : -(perBlockHeight * (containerIndex - fixedContainer)) - initialOffset;

    container.style.transform = `translateY(${calculateSize}px)`;
  }
};

const slideNewsY = (blockIndex, containerIndex) => {
  const container = document.querySelector(".news-series");
  const perBlockHeight = 204;
  const blocksPerSlide = 3;
  const initialOffset = 204;

  if (containerIndex === 1) {
    let calculateSize = blockIndex < blocksPerSlide
      ? 0
      : -(perBlockHeight * Math.floor(blockIndex / blocksPerSlide)) + initialOffset;

    container.style.transform = `translateY(${calculateSize}px)`;
  }
};

let newsSlideSize = 0;
const slideNewsDetailY = (blockIndex, containerIndex) => {
  const container = document.querySelector(".news-description");
  const containerOffsetY = container.clientHeight;
  const visibleOffset = 300;
  const maxSlideSize = containerOffsetY - visibleOffset;

  if (containerIndex === 2 && containerOffsetY > visibleOffset && newsSlideSize < maxSlideSize) {
    newsSlideSize += 10;
    container.style.transform = `translateY(-${newsSlideSize}px)`;
  }
};

const formatNewsDetail = () => {
  const container = document.querySelector(".news-description");
  const formatSize = 0;
  newsSlideSize = 0;
  container.style.transform = `translateY(-${formatSize}px)`;
};

const slideSeasonsX = (blockIndex, containerIndex) => {
  const container = document.querySelector(".program-cards");
  let calculateSize = -(268 * blockIndex) + 268;
  if (containerIndex === 1) {
    if (blockIndex < 2) {
      calculateSize = 0;
    }
      container.style.transform = `translateX(${calculateSize}px)`;
  }
};

const slideSearchY = (blockIndex, containerIndex) => {
  const container = document.querySelector(".program-clips-search");
  const perBlockHeight = 222;
  const blocksPerSlide = 3;
  const initialOffset = 120;
  if (container.classList.contains("active-container") && containerIndex === 1) {
    let calculateSize = blockIndex < blocksPerSlide
      ? 0
      : -(perBlockHeight * Math.floor(blockIndex / blocksPerSlide)) + initialOffset;

    container.style.transform = `translateY(${calculateSize}px)`;
  }
};


const combineSlideHelpers = {
  changeTvShowsX,
  slideStreamingY,
  slideHomeX,
  slideTvShowsY,
  slideNewsY,
  slideNewsDetailY,
  formatNewsDetail,
  slideSeasonsX,
  slideSearchY
};
export default combineSlideHelpers;
