import axios from "axios";

const setActiveHeader = (header) => {
  const oldHeader = document.querySelector(".header-item.active");
  if (oldHeader) {
    oldHeader.classList.remove("active");
  }
  const activeHeader = document.querySelector("." + header);
  if (activeHeader) {
    activeHeader.classList.add("active");
  }
};

const updateClock = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const clockElement = document.querySelector('.clock-elem');
  
  if (clockElement) {
      clockElement.textContent = `${hours}:${minutes}`;
  }
};
const initializeClock = () => {
  updateClock();
  setInterval(updateClock, 1000 * 60);
};

const setAdvertisements = () => {
  async function getAdvertisements(url) {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  
  const url = 'https://hbbappapi.tvekstra.com/api/advertisements/current/homepage/';
  getAdvertisements(url)
    .then(data => {
      const defaultUrl = "";
      const mediaSrc = data && data[0] && data[0].media_files && data[0].media_files[0] && data[0].media_files[0].file ? data[0].media_files[0].file : defaultUrl;
      const rightContent = document.querySelector(".banner-product-wrapper");
      rightContent.innerHTML = `<img class="banner-product" src="${mediaSrc}" alt="" />`;
    })
    .catch(error => {
      console.log('An error occurred:', error.message);
    });
};


const combineHelpers = {
  setActiveHeader,
  initializeClock,
  setAdvertisements,
};
export default combineHelpers;
