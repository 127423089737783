import axios from 'axios';
import moment from 'moment';
import { deactivateApp } from '../components/broadcast-object.js';
// import moment from 'moment';
import combineSlideHelpers from '../helpers/slideHelper.js';
import TVKEYS from '../tv/tvkeys.js';
import AbstractView from './AbstractView.js';

class StreamingDetail extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Yayın Akışı');
    this.setActiveHeader("header-streaming");
    this.detailId = params.id;
    this.programData = null;
    this.queryString = window.location.search;
    this.urlParams = new URLSearchParams(this.queryString);
    this.dayIndex = this.urlParams.get('day-index');
    this.programIndex  = this.urlParams.get('program-index');
    this.programDate = null;
  }

  async getProgramDetail() {
    const response = await axios.get(`https://hbbappapi.tvekstra.com/api/content_schedule/weekly/`);
    this.programDate = response.data.weekly_schedule[this.dayIndex].date;
    this.programData = response.data.weekly_schedule[this.dayIndex].content_schedule[this.programIndex];
  }

  async getHtml() {
    await this.getProgramDetail();

    const defaultUrlImage = "https://dummyimage.com/400x240/000000/ffffff.png";

    const primaryImage = this.programData.detail_files?.image_files[0];
    const fallbackImage = this.programData.content_files?.image_files[0];
    
    const imageUrl = primaryImage?.file || fallbackImage?.file || defaultUrlImage;
    return `
      <div class="left-content">
        <div class="streaming-detail-header">
        <img class="news-img" src="${imageUrl}" alt="news" />
        <div class="title">
          <div class="news-title-top">
            <div class="news-date">${this.programDate}</div>
            <div class="news-back-wrapper">
              <div class="news-back" href="/streaming" data-link="" tabindex="-1" >
                <i class="fa-solid fa-arrow-left"></i>Geri
              </div>
            </div>
            
            <div class="title-desc">${this.programData.title}</div>

            <div class="start-end-time">
              ${moment(this.programData.start_time, "HH:mm:ss").format("HH:mm")} - ${moment(this.programData.end_time, "HH:mm:ss").format("HH:mm")}
            </div>
            
          </div>
        </div>
        </div>

        <div class="news-desc-container">
          <div class="news-description">
            ${this.programData.description}
          </div>
        </div>

      </div>
    `;
  }

  async navigate() {
    const containers = document.querySelectorAll('.header-wrapper, .news-back-wrapper');
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex = 3;

    let navigationHandler = null;
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentBlockIndex > 0) {
              currentBlockIndex--;
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
            }
            break;
          case TVKEYS.VK_UP:
            if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
              combineSlideHelpers.formatNewsDetail();
            }
            break;
          case TVKEYS.VK_DOWN:
            if (currentContainerIndex < containers.length - 1) {
              currentContainerIndex++;
              currentBlockIndex = 0;
            }
            combineSlideHelpers.slideNewsDetailY(currentBlockIndex, currentContainerIndex);
            break;
            case TVKEYS.VK_RED:
            deactivateApp();
            break;
            case TVKEYS.VK_BACK:
              document.removeEventListener('keydown', navigationHandler);
              document.querySelector('.news-back').click();
              break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }


}

export default StreamingDetail;