import axios from 'axios';

class Weather {
  constructor() {
    this.weatherApiUrl = 'https://api.openweathermap.org/data/2.5/';
    this.weatherApiKey = '2d322edf55db7298a8eee42ebca27bfa';
    this.geoIpApiUrl = 'https://ip2loc.adekstra.com/api/geoip/city';
    this.city = undefined;
  }

  async getUserCity() {
    try {
      const response = await axios.get(this.geoIpApiUrl);
      this.city = response.data.city;
      return response.data.city;
    } catch (error) {
      console.error('Error fetching user city:', error);
      return null;
    }
  }

  async getWeatherForCity(city) {
    const query = `${this.weatherApiUrl}weather?q=${city}&appid=${this.weatherApiKey}&units=metric&lang=tr`;
    try {
      const response = await axios.get(query);
      return response.data;
    } catch (error) {
      console.error(`Error fetching weather for ${city}:`, error);
      return null;
    }
  }

  renderWeather(weatherData) {
    if (weatherData) {
      const iconCode = weatherData.weather[0].icon;
      const weatherDesc = weatherData.weather[0].description;
      document.querySelector('.weather-wrapper').innerHTML = `
        <div class="city">
          <span class="city-name">${this.city}</span>
          <span class="degree">${Math.round(weatherData.main.temp)} &#8451;</span>
        </div>
        <div class="weather-forecast">
          <img src="http://openweathermap.org/img/wn/${iconCode}@2x.png" alt="weather-icon" />
          <span>${weatherDesc}</span>
        </div>
      `;
    }
  }
}

const weather = new Weather();

(async () => {
  const userCity = await weather.getUserCity();
  if (userCity) {
    const weatherData = await weather.getWeatherForCity(userCity);
    weather.renderWeather(weatherData);
  }
})();