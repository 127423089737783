// Don't remove!
/* eslint no-bitwise: 0 */
// import TVKEYS from './keycodes';

import TVKEYS from "./tvkeys";

function setKeyset(mode) {
  try {
    let mask;

    const elemcfg = document.getElementById('oipfcfg');
    let app = document.getElementById('appmgr');

    const initKeys = window.campInfoOriginal?.system?.initKeys && window.campInfoOriginal?.system?.initKeys.length ? window.campInfoOriginal?.system?.initKeys.map((key) => TVKEYS[key]).reduce((total, last) => total | last) : TVKEYS.COLOR;
    // TODO: DEv Keys, Banner KEys
    if (mode === 'init') {
      mask = initKeys;
    } else if (mode === 'launcher') {
      mask = window.campInfoOriginal?.system?.initKeys || TVKEYS.COLOR;
    } else if (mode === 'banner') {
      mask = window.campInfoOriginal?.system?.initKeys || TVKEYS.COLOR;
    } else if (mode === 'dev') {
      mask = TVKEYS.DEV;
    } else if (mode === 'full') {
      console.log("full");
      mask = TVKEYS.FULL;
    } else if (mode === -1) {
      mask = TVKEYS.NONE;
      /** Go Back registered keysets in global * */
    } else {
      mask = TVKEYS.NONE;
    }

    // for HbbTV 1.0:
    if (app && app.getOwnerApplication) app = app.getOwnerApplication(document);

    try {
      app.privateData.keyset.setValue(mask);
    } catch (e) {
      ('HBB 0.5 Key Set');
      if (elemcfg.keyset) {
        if (!elemcfg.keyset.setValue) {
          elemcfg.keyset.setValue = function (val) {
            this.value = val;
          };
        }
        elemcfg.keyset.setValue(mask);
      }
    }
  } catch (e) {
    console.log('Cant set keyboard');
  }
}

const keyConfig = {
  setKeyset,
};

export default keyConfig;
