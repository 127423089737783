import AbstractView from './AbstractView.js';
// import videoIcon from '../../images/video-icon.png';
import TVKEYS from '../tv/tvkeys.js';
import axios from 'axios';
import combineSlideHelpers from '../helpers/slideHelper.js';
import { deactivateApp } from '../components/broadcast-object.js';

class ProgramDetail extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Dizi ve Programlar');
    this.setActiveHeader("header-tvshows");
    this.detailId = params.id;
    this.programData = null;
    this.seasonData = null;
  }

  async getSeasonData() {
    const response = await axios.get(`https://hbbappapi.tvekstra.com/api/content/${this.detailId}`);
    this.programData = response.data;
  }

  async programDetailData() {
    const response = await axios.get(`https://hbbappapi.tvekstra.com/api/content_season/${this.detailId}`);
    this.seasonData = response.data;
  }


  renderSeasons() {
    const defaultUrlImage = "https://dummyimage.com/240x144/000000/ffffff.png";

    return this.seasonData.map((item) => {
      const primaryImage = item.media_files.image_files[0];
      const fallbackImage = item.content?.media_files?.image_files[0];

      const imageUrl = primaryImage?.file || fallbackImage?.file || defaultUrlImage;

      return `
            <div class="program" href="/clips/${item.id}" data-link="" tabindex="-1">
                <img src="${imageUrl}" alt="program" />
                <div class="program-title">
                    Sezon ${item.season_num}
                </div>
            </div>
        `;
    }).join('');
  }



  async getHtml() {
    await this.programDetailData();
    await this.getSeasonData();
    const banner = this.programData.media_files.image_files[0].file;
    return `
  
    <div class="left-content">
    <div class="banner-wrapper">
      <img class="banner-program" src="${banner}" alt="yemeksepeti" />
    </div>
    
    <div class="program-cards">
    
      ${this.renderSeasons()}

    </div>

  </div>
  <div href="tv-shows" data-link="" class="back-tag" tabindex="-1"></div>
    `;
  }

  async navigate() {
    const containers = document.querySelectorAll('.header-wrapper, .program-cards');
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex = 2;

    let navigationHandler = null;
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentBlockIndex > 0) {
              currentBlockIndex--;
              combineSlideHelpers.slideSeasonsX(currentBlockIndex, currentContainerIndex);
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
              combineSlideHelpers.slideSeasonsX(currentBlockIndex, currentContainerIndex);
            }
            break;
          case TVKEYS.VK_UP:
            if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
            }
            break;
          case TVKEYS.VK_DOWN:
            if (currentContainerIndex < containers.length - 1) {
              currentContainerIndex++;
              currentBlockIndex = 0;
            }
            break;
          case TVKEYS.VK_BACK:
            document.removeEventListener('keydown', navigationHandler);
            document.querySelector('.back-tag').click();
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }

}

export default ProgramDetail;
