import AbstractView from './AbstractView.js';
import TVKEYS from '../tv/tvkeys.js';
import axios from 'axios';
import combineSlideHelpers from '../helpers/slideHelper.js';
import { deactivateApp } from '../components/broadcast-object.js';

class News extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Haberler');
    this.setActiveHeader("header-news");
    this.newsData = null;
  }

  async getNewsData() {
    const response = await axios.get(`https://hbbappapi.tvekstra.com/api/news/`);
    this.newsData = response.data;
  }


  renderNews() {
    return this.newsData.map((item) => {
      const imageUrl = item.media_files.image_files?.[0]?.file ?? "https://dummyimage.com/245x126/000000/ffffff.png";

      return (
        `
        <div class="news" href="/news/detail/${item.id}" data-link="" tabindex="-1" >
          <img src="${imageUrl}" alt="news" />
          <div class="news-title">
            ${item.title}
          </div>
        </div>
        `
      );
    }).join('');
  }

  async getHtml() {
    await this.getNewsData();
    return `
  <div class="left-content">
    <div class="news-series">

      ${this.renderNews()}

    </div>
  </div>
    `;
  }


  async navigate() {
    const containers = document.querySelectorAll('.header-wrapper, .featured-news-wrapper, .news-series');
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex = 3;

    let navigationHandler = null;
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentBlockIndex > 0) {
              currentBlockIndex--;
              combineSlideHelpers.slideNewsY(currentBlockIndex, currentContainerIndex);
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
              combineSlideHelpers.slideNewsY(currentBlockIndex, currentContainerIndex);

            }
            break;
          case TVKEYS.VK_UP:
            if (currentContainerIndex === 1 && currentBlockIndex > 2) {
              currentBlockIndex -= 3;
            }
            else if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
            }
            combineSlideHelpers.slideNewsY(currentBlockIndex, currentContainerIndex);
            break;
          case TVKEYS.VK_DOWN:
            if (currentContainerIndex === 1 && currentBlockIndex < blocks.length - 3) {
              currentBlockIndex += 3;
            }
            else if (currentContainerIndex < containers.length - 1) {
              currentContainerIndex++;
              currentBlockIndex = 0;
            }
            combineSlideHelpers.slideNewsY(currentBlockIndex, currentContainerIndex);
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }


}

export default News;
