import AbstractView from './AbstractView.js';
import TVKEYS from '../tv/tvkeys.js';
import axios from 'axios';
import { deactivateApp } from '../components/broadcast-object.js';
class ProgramClips extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Program Clips');
    this.setActiveHeader("header-tvshows");
    this.detailId = params.id;
    this.episodesdata = null;
    this.trailersData = null;
  }

  async getEpisodes() {
    const response = await axios.get(`https://hbbappapi.tvekstra.com/api/content_detail/season/${this.detailId}/MT01/`);
    this.episodesdata = response.data;
  }

  async getTrailers() {
    const response = await axios.get(`https://hbbappapi.tvekstra.com/api/content_detail/season/${this.detailId}/MT02/`);
    this.trailersData = response.data;
  }

  renderEpisodes() {
    const defaultUrlImage = "https://dummyimage.com/240x144/000000/ffffff.png";

    return this.episodesdata.map((item, index) => {
      const videoFile = item.media_files?.video_files?.[0];
      const link = videoFile?.file || "errorMedia";

      const mediaImageFile = item.media_files?.image_files[0];
      const contentSeasonImageFile = item.content_season?.media_files?.image_files[0];

      const mediaImage = mediaImageFile?.file || contentSeasonImageFile?.file || defaultUrlImage;

      return `
            <div class="program" href="/media/${this.detailId}?name=${item.title}&amp;media=${link}&amp;index=${index}&amp;detail-id=${this.detailId}" data-link="" tabindex="-1">
                <img src="${mediaImage}" alt="program" />
                <div class="program-title">
                    ${item.title}
                </div>
                <div class="program-desc">
                    ${item.episode_num}. Bölüm
                </div>
            </div>
        `;
    }).join('');
  }

  renderTrailers() {
    const defaultUrlImage = "https://dummyimage.com/240x144/000000/ffffff.png";

    return this.trailersData.map((item, index) => {
      const videoFile = item.media_files?.video_files?.[0];
      const link = videoFile?.file || "errorMedia";

      const mediaImageFile = item.media_files?.image_files[0];
      const contentSeasonImageFile = item.content_season?.media_files?.image_files[0];

      const mediaImage = mediaImageFile?.file || contentSeasonImageFile?.file || defaultUrlImage;

      return `
            <div class="program" href="/media/${this.detailId}?name=${item.title}&amp;media=${link}&amp;index=${index}&amp;detail-id=${this.detailId}" data-link="" tabindex="-1">
                <img src="${mediaImage}" alt="program" />
                <div class="program-title">
                    ${item.title}
                </div>
                <div class="program-desc">
                    ${item.episode_num}. Bölüm
                </div>
            </div>
        `;
    }).join('');
  }

  async getHtml() {
    await this.getEpisodes();
    await this.getTrailers();
    return `
  
  <div class="left-content">
    <div class="program-clips-title">BÖLÜMLER</div>
    <div class="program-clips-list">
      
      ${this.renderEpisodes()}
      
    </div>

    <div class="trailers-clips-title">FRAGMANLAR</div>
    <div class="trailers-clips-list">
      
      ${this.renderTrailers()}
      
    </div>
    
  </div>
  <div href="/program-detail/${localStorage.getItem('pagePath')}" data-link="" class="back-tag" tabindex="-1"></div>
    `;
  }


  async navigate() {
    const containers = document.querySelectorAll('.header-wrapper, .program-clips-list, .trailers-clips-list');
    const containersArray = [];
    for (let i = 0; i < containers.length; i++) {
      containersArray.push(containers[i]);
    }
    let currentContainerIndex = 0;
    let currentBlockIndex = 0;

    let navigationHandler = null;
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {
      let blocks = [];
      const children = containers[currentContainerIndex].children;
      for (let i = 0; i < children.length; i++) {
        blocks.push(children[i]);
      }

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            if (currentBlockIndex > 0) {
              currentBlockIndex--;
            }
            break;
          case TVKEYS.VK_RIGHT:
            if (currentBlockIndex < blocks.length - 1) {
              currentBlockIndex++;
            }
            break;
          case TVKEYS.VK_UP:
            if (currentContainerIndex > 0) {
              currentContainerIndex--;
              currentBlockIndex = 0;
            }
            break;
          case TVKEYS.VK_DOWN:
            if (currentContainerIndex < containers.length - 1) {
              currentContainerIndex++;
              currentBlockIndex = 0;
            }
            break;
          case TVKEYS.VK_BACK:
            document.removeEventListener('keydown', navigationHandler);
            document.querySelector('.back-tag').click();
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          default:
            break;
        }

        containersArray.forEach((container, index) => {
          if (index === currentContainerIndex) {
            container.classList.add('active-container');
            blocks = [].slice.call(containers[index].children);
            const selectedElementList = document.querySelectorAll('.selected');
            for (let i = 0; i < selectedElementList.length; i++) {
              selectedElementList[i].classList.remove('selected');
            }
          } else {
            container.classList.remove('active-container');
          }
        });
        blocks.forEach((block, index) => {
          if (index === currentBlockIndex) {
            block.classList.add('selected');
          } else {
            block.classList.remove('selected');
          }
        });
      };
    }
  }


}

export default ProgramClips;
