
import TVKEYS from "../../tv/tvkeys";
import AbstractView from "../AbstractView";
import flag from "../../../images/games/maze-game/flag.png";
import { deactivateApp } from "../../components/broadcast-object";
class MazeGame extends AbstractView {
  constructor(params) {
    super(params);
    this.setTitle('Ana Sayfa');
    this.setActiveHeader('header-games');
    this.playing = true;
    this.handled = false;
    this.m = undefined;
    this.counterInterval = undefined;
  }

  modelFunGo() {
    const result = document.createElement("div");
    const wrapper = document.querySelector(".maze-game-wrapper");
    result.classList.add("result-elem");
    wrapper.append(result);
    result.innerHTML = "KAYBETTİNİZ!";
  }


  modelFunWin() {
    const result = document.createElement("div");
    const wrapper = document.querySelector(".maze-game-wrapper");
    result.classList.add("result-elem");
    wrapper.append(result);
    result.innerHTML = "KAZANDINIZ!";
  }

  defineLibraries() {
    const that = this;
    function startTimer(duration, display) {
      var start = Date.now(),
        diff,
        minutes,
        seconds;
      function timer() {
        if (that.playing) {
          diff = duration - (((Date.now() - start) / 1000) | 0);
          minutes = (diff / 60) | 0;
          seconds = (diff % 60) | 0;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;
          display.textContent = minutes + ":" + seconds;
          if (diff == 10) {
            display.style.color = "#fa0e17";
          }
          if (diff <= 0) {
            display.textContent = "Game Over";
            display.style.right = "220px";
            start = Date.now() + 1000;
            that.playing = false;
            that.modelFunGo();
          }
        }
      }
      timer();
      that.counterInterval = setInterval(timer, 1000);
    }
    //window.onload = function () {
    var twominutes = 60;
    var x = document.querySelector("#timerel");
    startTimer(twominutes, x);
    //}
    // ADEKSTRA.p.campaign.variables.playing = true
    // ----------------------> window.addEventListener("keydown", doKeyDown, true);
    /* -------------------------------- */
    var dsd = function (size) {
      this.N = size;
      this.P = new Array(this.N);
      this.R = new Array(this.N);
      this.init = function () {
        for (var i = 0; i < this.N; i++) {
          this.P[i] = i;
          this.R[i] = 0;
        }
      };
      this.union = function (x, y) {
        var u = this.find(x);
        var v = this.find(y);
        if (this.R[u] > this.R[v]) {
          this.R[u] = this.R[v] + 1;
          this.P[u] = v;
        }
        else {
          this.R[v] = this.R[u] + 1;
          this.P[v] = u;
        }
      };
      this.find = function (x) {
        if (x == this.P[x])
          return x;
        this.P[x] = this.find(this.P[x]);
        return this.P[x];
      };
    };
    function random(min, max) { return (min + (Math.random() * (max - min))); }
    function randomChoice(choices) { return choices[Math.round(random(0, choices.length - 1))]; }
    /* ------------------------------ */
    var maze = function (X, Y) {
      this.N = X;
      this.M = Y;
      this.S = 25;
      this.moves = 0;
      this.Board = new Array(2 * this.N + 1);
      this.EL = new Array();
      this.vis = new Array(2 * this.N + 1);
      this.delay = 2;
      this.x = 1;
      this.init = function () {
        for (var i = 0; i < 2 * this.N + 1; i++) {
          this.Board[i] = new Array(2 * this.M + 1);
          this.vis[i] = new Array(2 * this.M + 1);
        }
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < 2 * this.N + 1; i++) {
          for (var j = 0; j < 2 * this.M + 1; j++) {
            if (!(i % 2) && !(j % 2)) {
              this.Board[i][j] = " + ";
            }
            else if (!(i % 2)) {
              this.Board[i][j] = " - ";
            }
            else if (!(j % 2)) {
              this.Board[i][j] = " | ";
            }
            else {
              this.Board[i][j] = " ";
            }
            this.vis[i][j] = 0;
          }
        }
      };
      this.add_edges = function () {
        for (var i = 0; i < this.N; i++) {
          for (var j = 0; j < this.M; j++) {
            if (i != this.N - 1) {
              this.EL.push([[i, j], [i + 1, j], 1]);
            }
            if (j != this.M - 1) {
              this.EL.push([[i, j], [i, j + 1], 1]);
            }
          }
        }
      };
      //Hash function
      this.h = function (e) {
        return e[1] * this.M + e[0];
      };
      this.randomize = function (EL) {
        for (var i = 0; i < EL.length; i++) {
          var si = Math.floor(Math.random() * 387) % EL.length;
          var tmp = EL[si];
          EL[si] = EL[i];
          EL[i] = tmp;
        }
        return EL;
      };
      this.breakwall = function (e) {
        var x = e[0][0] + e[1][0] + 1;
        var y = e[0][1] + e[1][1] + 1;
        this.Board[x][y] = " ";
      };
      this.gen_maze = function () {
        this.EL = this.randomize(this.EL);
        var D = new dsd(this.M * this.M);
        D.init();
        var s = this.h([0, 0]);
        var e = this.h([this.N - 1, this.M - 1]);
        this.Board[1][0] = " ";
        this.Board[2 * this.N - 1][2 * this.M] = " ";
        //Run Kruskal
        for (var i = 0; i < this.EL.length; i++) {
          var x = this.h(this.EL[i][0]);
          var y = this.h(this.EL[i][1]);
          if (D.find(s) == D.find(e)) {
            if (!(D.find(x) == D.find(s) && D.find(y) == D.find(s))) {
              if (D.find(x) != D.find(y)) {
                D.union(x, y);
                this.breakwall(this.EL[i]);
                this.EL[i][2] = 0;
              }
            }
            //break;
          }
          else if (D.find(x) != D.find(y)) {
            D.union(x, y);
            this.breakwall(this.EL[i]);
            this.EL[i][2] = 0;
          }
          else {
            continue;
          }
        }
      };


      this.draw_canvas = function (id) {
        this.canvas = document.getElementById(id);
        var scale = this.S;
        var temp = [];
        if (this.canvas.getContext) {
          this.ctx = this.canvas.getContext("2d");
          this.Board[1][0] = "$";
          for (var i = 0; i < 2 * this.N + 1; i++) {
            for (var j = 0; j < 2 * this.M + 1; j++) {
              if (this.Board[i][j] != " ") {//} && this.Board[i][j] != "&") {
                this.ctx.fillStyle = "#0b052d";
                this.ctx.fillRect(scale * i, scale * j, scale, scale);
              }
              else if (i < 5 && j < 5)
                temp.push([i, j]);
            }
          }
          var x = randomChoice(temp);
          //    console.log(temp)
          this.Board[x[0]][x[1]] = "&";
          this.ctx.fillStyle = "#1a933d";
          this.ctx.fillRect(scale * x[0], scale * x[1], scale, scale);
        }
      };
      // eslint-disable-next-line no-unused-vars
      this.checkPos = function (id) {
        for (var i = 0; i < 2 * this.N + 1; i++) {
          for (var j = 0; j < 2 * this.M + 1; j++) {
            if (this.Board[i][j] == "&") {
              return [i, j];
            }
          }
        }
      };
      this.moveclear = function (a, b) {
        var scale = this.S;
        this.ctx = this.canvas.getContext("2d");
        this.ctx.fillStyle = "#bcf549";
        this.ctx.fillRect(scale * a, scale * b, scale, scale);
        this.Board[a][b] = " ";
      };
      this.move = function (a, b) {
        var scale = this.S;
        this.ctx = this.canvas.getContext("2d");
        this.ctx.fillStyle = "#1a933d";
        this.ctx.fillRect(scale * a, scale * b, scale, scale);
        this.Board[a][b] = "&";
      };
      this.moveup = function (id) {
        var cord = this.checkPos(id);
        // eslint-disable-next-line no-unused-vars
        var scale = this.S;
        var i = cord[0];
        var j = cord[1];
        j -= 1;
        if (j < 0)
          return;
        else if (j > 2 * this.M)
          return;
        else if (this.Board[i][j] == " ") {
          this.moveclear(i, j + 1);
          this.move(i, j);
          this.moves += 1;
        }
        else
          return;
      };
      this.movedown = function (id) {
        var cord = this.checkPos(id);
        // eslint-disable-next-line no-unused-vars
        var scale = this.S;
        var i = cord[0];
        var j = cord[1];
        j += 1;
        if (j < 0)
          return;
        else if (j > 2 * this.M)
          return;
        else if (this.Board[i][j] == " ") {
          this.moveclear(i, j - 1);
          this.move(i, j);
          this.moves += 1;
        }
        else
          return;
      };
      this.moveleft = function (id) {
        var cord = this.checkPos(id);
        // eslint-disable-next-line no-unused-vars
        var scale = this.S;
        var i = cord[0];
        var j = cord[1];
        i -= 1;
        if (i < 0)
          return;
        else if (i > 2 * this.N)
          return;
        else if (this.Board[i][j] == " ") {
          this.moveclear(i + 1, j);
          this.move(i, j);
          this.moves += 1;
        }
        else
          return;
      };
      this.moveright = function (id) {
        var cord = this.checkPos(id);
        // eslint-disable-next-line no-unused-vars
        var scale = this.S;
        var i = cord[0];
        var j = cord[1];
        i += 1;
        if (i < 0)
          return;
        else if (i > 2 * this.N)
          return;
        else if (this.Board[i][j] == " ") {
          this.moveclear(i - 1, j);
          this.move(i, j);
          this.moves += 1;
        }
        else
          return;
      };
      this.checker = function (id) {
        //      console.log("win");
        var cord = this.checkPos(id);
        var i = cord[0];
        var j = cord[1];
        //    console.log(cord); 
        if ((i == 19 && j == 20) || (i == 1 && j == 0)) {
          that.modelFunWin();
          //           alert("YOU WIN, CONGRATULATIONS!");
          //
          return 1;
        }
        return 0;
      };
      this.getMoves = function () {
        return this.moves;
      };
    };
    that.m = new maze(10, 10);
    //var m = new maze(10, 10);
    that.m.init();
    that.m.add_edges();
    that.m.gen_maze();
    that.m.draw_canvas("canvas");
    // drawMoves();
    //window.Game = maze;,
  }

  drawMoves() {
    document.getElementById("c").innerHTML = "Moves: " + this.m.getMoves();
  }

  moveChecker() {
    if (this.m.checker("canvas")) {
      this.playing = false;
      console.log(this.m.getMoves);
    }
  }

  moveUp() {
    if (this.playing) {
      this.m.moveup("canvas");
      this.handled = true;
      this.drawMoves();
      this.moveChecker();
    }
  }
  moveDown() {
    if (this.playing) {
      this.m.movedown("canvas");
      this.handled = true;
      this.drawMoves();
      this.moveChecker();
    }
  }
  moveRight() {
    if (this.playing) {
      this.m.moveright("canvas");
      this.handled = true;
      this.drawMoves();
      this.moveChecker();
    }
  }
  moveLeft() {
    if (this.playing) {
      this.m.moveleft("canvas");
      this.handled = true;
      this.drawMoves();
      this.moveChecker();
    }
  }



  async getHtml() {
    return `
      <div class="maze-game-wrapper">
        <div id="wrapper">

          <div class="maze-game-back" href="/games" data-link="" tabindex="-1" ><i class="fa-solid fa-stop"></i>Geri</div>
          <div class="maze-game-restart" ><i class="fa-solid fa-stop"></i>Yeniden Oyna</div>

          <div id="maze">
            <div id="c">
            Moves: 0
            </div>
            <canvas id="canvas" width="523" height="523" style="margin-left: 40px">
              This text is displayed if your browser does not support HTML5 Canvas.
            </canvas>
            <div id="timerel">01:00
            </div>
          </div>
          <img id="flag" src="${flag}" alt="" />
        </div>
      </div>
  
    `;
  }

  async navigate() {
    this.defineLibraries();

    const that = this;
    let navigationHandler = null;
    document.addEventListener('keydown', navigationHandler = createNavigationHandler());

    function createNavigationHandler() {

      return function (e) {
        const target = document.querySelector('.selected');

        switch (e.keyCode) {
          case TVKEYS.VK_ENTER:
            if (target) {
              if (target.hasAttribute('data-link')) {
                document.removeEventListener('keydown', navigationHandler);
              }
              document.querySelector('.selected').click();
            } else {
              console.log('unknown selected target');
            }
            break;
          case TVKEYS.VK_LEFT:
            that.moveLeft();
            break;
          case TVKEYS.VK_RIGHT:
            that.moveRight();
            break;
          case TVKEYS.VK_UP:
            that.moveUp();
            break;
          case TVKEYS.VK_DOWN:
            that.moveDown();
            break;
          case TVKEYS.VK_BLUE:
            clearInterval(that.counterInterval);
            document.removeEventListener('keydown', navigationHandler);
            document.querySelector(".maze-game-back").click();
            break;
          case TVKEYS.VK_RED:
            deactivateApp();
            break;
          case TVKEYS.VK_GREEN:
            clearInterval(that.counterInterval);
            document.removeEventListener('keydown', navigationHandler);
            document.querySelector(".maze-game-back").click();
            setTimeout(() => {
              document.querySelectorAll(".game-item")[1].click();
            }, 1000);
            break;
          default:
            break;
        }

      };
    }

  }

  async afterRender() {

  }



}

export default MazeGame;


